import "core-js/modules/es.array.push.js";
import SplitPane from '@/components/SplitPane/SplitPane.vue';
import { defineComponent, provide, reactive, toRefs, computed, onBeforeUnmount, watch, defineAsyncComponent, nextTick } from 'vue';
import LiveMap from '../live/LiveMap.vue';
import LiveSide from '../live/LiveSide.vue';
import APIPos from '@/api/live';
import { useMessage } from '@/hooks/web/useMessage';
import { isNullOrUnDef } from '@/utils/is';
import { useDebounce } from '@/hooks/core/useDebounce';
import { useRoute } from "vue-router";
import sleep from '../../utils/sleep';
import { useStore } from "vuex";
export default defineComponent({
  name: 'Live',
  components: {
    LiveMap,
    LiveSide,
    SplitPane,
    LiveFence: () => import('../live/LiveFence.vue'),
    CmdDialog: () => import('@/components/Instruction'),
    StudentInfo: () => import('@/components/StudentInfo/StudentInfo.vue'),
    MpttDialog: defineAsyncComponent(() => import('@/components/MpttFormDialog.vue'))
  },
  // setup(props, { attrs, emit, expose, slots })
  setup() {
    const route = useRoute();
    const {
      state
    } = useStore();
    const messageBox = useMessage();
    const intervalObj = {
      ms: 1,
      timerId: null
    };
    const imei = computed(() => route.query.imei);
    const refData = reactive({
      refMap: null,
      refFence: null,
      fenceObj: {
        visible: false,
        mapZoom: 12,
        mapCenter: null
      },
      left: {
        min: 20,
        max: 30,
        default: 20
      },
      right: {
        min: 70,
        max: 80,
        default: 80
      }
    });
    const refRoot = reactive({
      // 员工列表
      studentList: [],
      // 加载员工列表时的加载状态
      studentLoading: false,
      // 选择的岗位类型ID
      filterClassIds: [],
      //imei号
      filterImei: '',
      // 加载员工在线状态
      filterType: 0,
      // 员工列表总数
      studentTotal: 0,
      // 员工列表在线数
      studentOnline: 0,
      // 员工列表离线数, 包括从未上线
      studentOffline: 0,
      // 员工列表勾选员工的cardId列表
      studentCheckedIds: [],
      // 当前选择的员工证Id
      currentCardId: null,
      // 当前岗位类型Id
      currentClassId: null,
      updateTime: 0,
      // 指令下发Dialog参数
      cmdProps: {
        visible: false,
        objectid: 0
      },
      // 员工证详细
      cardDetailProps: {
        objectid: 0,
        visible: false
      },
      //mptt详细
      mpttDetailProps: {
        objectid: 0,
        visible: false
      }
    });
    async function getStudentPos() {
      try {
        const holdIds = refRoot.filterClassIds;
        const type = refRoot.filterType;
        const mdtid = refRoot.filterImei;
        const result = await APIPos.getStudentPos({
          holdIds,
          type,
          mdtid
        });
        if (result.code !== 0) return;
        const {
          posList,
          total,
          online,
          offline
        } = result.data;
        refRoot.studentTotal = total;
        refRoot.studentOnline = online;
        refRoot.studentOffline = offline;
        mergeStudentList(posList);
        // 更新数据
      } catch (error) {
        console.error(error);
      }
    }
    async function loadStudent() {
      try {
        refRoot.studentLoading = true;
        refRoot.studentList = [];
        const holdIds = refRoot.filterClassIds;
        if (holdIds.length === 0 && !imei.value) return;
        await getStudentPos();
        startTiming();
      } catch (error) {
        console.error(error);
      } finally {
        refRoot.studentLoading = false;
      }
    }
    function startTiming() {
      const updateTime = 30;
      refRoot.updateTime = updateTime;
      intervalObj.timerId = setInterval(() => {
        if (!intervalObj.timerId) return;
        refRoot.updateTime--;
        if (refRoot.updateTime <= 0) {
          getStudentPos();
          refRoot.updateTime = updateTime;
        }
      }, 1000);
    }
    function stopTiming() {
      clearInterval(intervalObj.timerId);
      intervalObj.timerId = null;
      refRoot.studentTotal = 0;
      refRoot.studentOnline = 0;
      refRoot.studentOffline = 0;
      refRoot.updateTime = 0;
      refRoot.currentCardId = null;
      refRoot.studentCheckedIds = [];
      refRoot.studentList = [];
      refRoot.studentLoading = false;
    }
    function initProvide() {
      provide('rootProvide', refRoot);
    }
    // 合并
    function mergeStudentList(posList) {
      if (refRoot.studentList.length === 0) {
        refRoot.studentList = posList;
        return;
      }
      // 移除posList不存在的员工, 因为是全量更新,  所以更新的数据是所有最新的数据
      for (let i = refRoot.studentList.length - 1; i >= 0; i--) {
        const student = refRoot.studentList[i];
        if (posList.some(pos => pos.objectid === student.objectid)) continue;
        refRoot.studentList.splice(i, 1);
      }
      while (posList.length) {
        const pos = posList.shift();
        // 下标索引
        const index = refRoot.studentList.findIndex(student => student.objectid === pos.objectid);
        // 新增
        if (index === -1) {
          refRoot.studentList.push(pos);
        } else {
          // 合并项
          Object.assign(refRoot.studentList[index], pos);
        }
      }
    }
    // 添加围栏
    async function addFence(drawType) {
      if (isNullOrUnDef(refData.refMap)) return;
      if (isNullOrUnDef(refData.refFence)) return;
      const mapOptions = refData.refMap.getMapOptions();
      refData.fenceObj.mapZoom = mapOptions.zoom;
      refData.fenceObj.mapCenter = mapOptions.center;
      // 清空地图上的围栏
      refData.refMap.renderPolygon();
    }
    // 点击围栏
    function fenceClick(row) {
      if (isNullOrUnDef(row)) {
        // 清空地图上的围栏
        refData.refMap.renderPolygon();
        return;
      }
      if (row.type === 1) {
        refData.refMap.renderPolygon(row.pointList);
      } else if (row.type === 2) {
        refData.refMap.renderPolygon(row.pointList);
      }
    }
    const [debounceLoadStudent] = useDebounce(loadStudent, 300);
    watch([() => refRoot.filterClassIds, () => refRoot.filterType, () => refRoot.filterImei], async () => {
      stopTiming();
      refRoot.studentLoading = true;
      await nextTick();
      debounceLoadStudent();
      // loadStudent();
    }, {
      deep: true
    });
    watch(() => imei.value, async val => {
      refRoot.filterImei = val;
      await sleep(1500);
      if (refRoot.studentList.length) {
        refRoot.currentCardId = refRoot.studentList[0].objectid;
        refRoot.studentCheckedIds.push(refRoot.currentCardId);
      } else {
        state.userInfo && messageBox.warning('查无此设备号码');
      }
    }, {
      immediate: true
    });
    onBeforeUnmount(() => {
      stopTiming();
    });
    initProvide();
    return {
      ...toRefs(refData),
      ...toRefs(refRoot),
      addFence,
      fenceClick
    };
  }
});