import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-645a7f1f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "livepage_wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LiveMap = _resolveComponent("LiveMap");
  const _component_LiveFence = _resolveComponent("LiveFence");
  const _component_CmdDialog = _resolveComponent("CmdDialog");
  const _component_StudentInfo = _resolveComponent("StudentInfo");
  const _component_MpttDialog = _resolveComponent("MpttDialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_LiveMap, {
    ref: "refMap",
    class: "livepage_map",
    isEmbed: ""
  }, null, 512), _ctx.fenceObj.visible ? (_openBlock(), _createBlock(_component_LiveFence, {
    key: 0,
    ref: "refFence",
    class: "livepage_fence",
    mapZoom: _ctx.fenceObj.mapZoom,
    mapCenter: _ctx.fenceObj.mapCenter,
    onAdd: _ctx.addFence,
    onRowClick: _ctx.fenceClick
  }, null, 8, ["mapZoom", "mapCenter", "onAdd", "onRowClick"])) : _createCommentVNode("", true), _createVNode(_component_CmdDialog, {
    visible: _ctx.cmdProps.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.cmdProps.visible = $event),
    objectid: _ctx.cmdProps.objectid
  }, null, 8, ["visible", "objectid"]), _createVNode(_component_StudentInfo, {
    visible: _ctx.cardDetailProps.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => _ctx.cardDetailProps.visible = $event),
    objectid: _ctx.cardDetailProps.objectid
  }, null, 8, ["visible", "objectid"]), _createVNode(_component_MpttDialog, {
    visible: _ctx.mpttDetailProps.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => _ctx.mpttDetailProps.visible = $event),
    objectid: _ctx.mpttDetailProps.objectid
  }, null, 8, ["visible", "objectid"])]);
}